@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow&family=Outfit&family=Pragati+Narrow&display=swap');

.App {
  text-align: center;
  font-family: 'Outfit', sans-serif;
}

.RecordBoard {
  font-family: "Outfit", sans-serif, monospace;
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 800px) {
  .RecordBoard {
    font-family: "Archivo Narrow", sans-serif, monospace;
    font-size: 14px;
  }
}