.trip-list {
    margin: 20px auto;
  }
  .trip-list ul {
    padding: 0;
  }
  .trip-list li {
    border: 1px solid #e4e4e4;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 4px 4px 6px rgba(0,0,0,0.05);
    border-radius: 4px;
    list-style-type: none;
    margin: 20px 0;
  }
  .trip-list .filters {
    display: flex;
    gap: 10px;
  }
  .trip-list button {
    padding: 10px;
    font-size: 16px;
    flex-grow: 1;
  }