@import url(https://fonts.googleapis.com/css2?family=Archivo+Narrow&family=Outfit&family=Pragati+Narrow&display=swap);

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Outfit', sans-serif;
}

.RecordBoard {
  font-family: "Outfit", sans-serif, monospace;
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 800px) {
  .RecordBoard {
    font-family: "Archivo Narrow", sans-serif, monospace;
    font-size: 14px;
  }
}
.trip-list {
    margin: 20px auto;
  }
  .trip-list ul {
    padding: 0;
  }
  .trip-list li {
    border: 1px solid #e4e4e4;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 4px 4px 6px rgba(0,0,0,0.05);
    border-radius: 4px;
    list-style-type: none;
    margin: 20px 0;
  }
  .trip-list .filters {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
  }
  .trip-list button {
    padding: 10px;
    font-size: 16px;
    flex-grow: 1;
  }
